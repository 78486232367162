"use client";

import isEqual from "lodash/isEqual";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useProducts } from "@microsite/lib/api/products/fetch";
import findVariant from "@microsite/lib/productOptions/findVariant";
import getDefaultOptionsValueIds from "@microsite/lib/productOptions/getDefaultOptionsValueIds";
import {
  BuyModalities,
  ImageGridFormat,
  Maybe,
  Product,
  ShopifyProductVariant,
  ShopifySellingPlan,
  ShopifySellingPlanGroup,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { CampaignSuperfiliateMicrositeContext } from "@utils/microsites/contexts/campaignSuperfiliate";
import findMicrositeSection from "@utils/microsites/personalization/findMicrositeSection";
import { ProductVariation } from "@utils/microsites/personalization/sectionsVariationsEnums";
import { Sections } from "@utils/microsites/types/personalization";
import { OrderInterval, BUYING_MODALITIES } from "@utils/types";

interface ProductsContextProps {
  pickedProducts?: Product[];
  totalProducts?: number;

  product?: Product | null;
  variant: ShopifyProductVariant | undefined;
  options: string[] | undefined;
  sellingOption: OrderInterval | undefined;
  sellingPlanGroup: Maybe<ShopifySellingPlanGroup> | undefined;
  sellingPlan: ShopifySellingPlan | undefined;
  pickedProductId: string | undefined;

  setProduct: (product: Product) => void;
  setVariant: (variant: ShopifyProductVariant) => void;
  setOptions: (options: string[]) => void;
  setSellingOption: (option: OrderInterval) => void;
  setSellingPlan: (sellingPlan: ShopifySellingPlan | undefined) => void;
  setPickedProductId: (id: string | undefined) => void;
  loadMoreProducts?: () => void;
  isLoadingMoreProducts?: boolean;
  imageGridFormat?: ImageGridFormat | null | undefined;
}

const MAX_PRODUCTS = {
  [ProductVariation.DEFAULT]: 15,
  [ProductVariation.GRID_STYLE_1]: 8,
  [ProductVariation.GRID_STYLE_2]: 4,
} as const;

export const ProductsContext = createContext<ProductsContextProps>({
  /* Products loaded from the microsite template, picked by the end-customer */
  pickedProducts: [],

  totalProducts: 0,

  loadMoreProducts: () => {
    /**/
  },
  isLoadingMoreProducts: false,

  /* Product ID selected by the customer, if multiple */
  pickedProductId: undefined,
  /* Product ID setter */
  setPickedProductId: (_: string | undefined) => {
    /**/
  },

  /* Product currently selected and loaded on the page */
  product: undefined,
  /* Product setter */
  setProduct: (_: Product) => {
    /**/
  },

  /* Variant currently selected and loaded on the page */
  variant: undefined,
  /* Variant setter */
  setVariant: (_: ShopifyProductVariant) => {
    /**/
  },

  /* Options currently selected, that maps to the variant above */
  options: undefined,
  /* Options setter */
  setOptions: (_: string[]) => {
    /**/
  },

  /* Selling Option currently selected, that maps to either subscription or 1-time */
  sellingOption: undefined,
  /* Selling Option setter */
  setSellingOption: (_: OrderInterval) => {
    /**/
  },

  /* The Selling Plan Group set for the product or variant */
  sellingPlanGroup: undefined,
  /* The Selling Plan selected, which defines the subscription cadence */
  sellingPlan: undefined,
  /* Selling Plan setter */
  setSellingPlan: (_: ShopifySellingPlan | undefined) => {
    /**/
  },
  /* The format in which the images are displayed in the grid */
  imageGridFormat: undefined,
});

/**
 * This provider contains all the product and variant related details, so they
 * can be used by all the surrounding components of the app
 */
export const ProductsProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [product, setProduct] = useState<Product>();
  const [variant, setVariant] = useState<ShopifyProductVariant>();
  const [options, setOptions] = useState<string[]>();
  const [sellingOption, setSellingOption] = useState<OrderInterval>();
  const [sellingPlan, setSellingPlan] = useState<
    ShopifySellingPlan | undefined
  >();
  const [pickedProductId, setPickedProductId] = useState<string>();
  const [pickedProducts, setPickedProducts] = useState<Product[]>([]);
  const page = useRef(0);

  const campaignSuperfiliateMicrosite = useContext(
    CampaignSuperfiliateMicrositeContext,
  );
  const [isLoadingMoreProducts, setIsLoadingMoreProducts] = useState(false);

  const { personalization } = campaignSuperfiliateMicrosite;

  const sellingPlanGroup =
    product?.shopifyProduct?.shopifySellingPlanGroup ||
    variant?.shopifySellingPlanGroup;

  const productSection = useMemo(
    () => findMicrositeSection(personalization, Sections.Product),
    [personalization],
  );

  const imageGridFormat = productSection?.imageGridFormat;

  let variation = ProductVariation.DEFAULT;
  if (
    Object.values(ProductVariation).some(
      (variation) => variation === productSection?.variation,
    )
  ) {
    variation = productSection?.variation as ProductVariation;
  }
  const maxProducts: number = MAX_PRODUCTS[variation];

  const pickedProductIds = productSection?.pickedProductIds || [];
  const pickedProductIdsRefs = useRef<string[]>([]);

  useEffect(() => {
    const firstOptions = getDefaultOptionsValueIds(product);
    const firstVariant =
      findVariant(firstOptions || [], product?.shopifyProduct) ||
      product?.shopifyProduct?.productVariants?.[0];

    setVariant(firstVariant);
    setOptions(firstVariant?.productOptionMap?.productOptionValueIds);
  }, [product]);

  useEffect(() => {
    const buyModalities = product?.buyModalities || BuyModalities.AllPossible;

    const hasSubscription = Boolean(
      BUYING_MODALITIES.subscription.includes(buyModalities) &&
        sellingPlanGroup,
    );

    setSellingOption(
      hasSubscription ? OrderInterval.SUBSCRIPTION : OrderInterval.ONE_TIME,
    );
  }, [variant]);

  useEffect(() => {
    const updatedVariant = findVariant(
      options || [],
      product?.shopifyProduct,
      true,
    );

    const mappedIds = updatedVariant?.productOptionMap?.productOptionValueIds;

    /* This validation is required to select the correct variant on the UI */
    if (mappedIds !== options) return setOptions(mappedIds);
    if (updatedVariant) setVariant(updatedVariant);
  }, [options]);

  useEffect(() => {
    setSellingPlan(sellingPlanGroup?.shopifySellingPlans?.[0]);
  }, [sellingPlanGroup]);

  const { mutate: firstPageMutate } = useProducts(
    pickedProductIds.slice(0 * maxProducts, (0 + 1) * maxProducts),
  );

  const { mutate } = useProducts(
    pickedProductIds.slice(
      page.current * maxProducts,
      (page.current + 1) * maxProducts,
    ),
  );

  const loadProducts = async (firstPage?: boolean) => {
    setIsLoadingMoreProducts(true);

    const fetcher = firstPage ? firstPageMutate : mutate;

    await fetcher().then((newPage) => {
      setPickedProducts((products) => [
        ...products,
        ...((newPage?.products?.nodes || []) as Product[]),
      ]);
    });

    page.current++;
    setIsLoadingMoreProducts(false);
  };

  const loadMoreProducts = () => loadProducts(false);

  useEffect(() => {
    if (!pickedProductId) setPickedProductId(pickedProducts?.[0]?.id);
  }, [pickedProducts]);

  useEffect(() => {
    /*
      This should make the render correct for microsites where the pickedProductIds does not change
      But will also update the pickedProducts when the pickedProductIds changes on michelangelo
    */
    if (isEqual(pickedProductIdsRefs.current, pickedProductIds)) return;

    pickedProductIdsRefs.current = pickedProductIds;
    page.current = 0;
    setPickedProducts([]);
    setPickedProductId(undefined);
    loadProducts(true);
  }, [pickedProductIds]);

  return (
    <ProductsContext.Provider
      value={{
        pickedProducts,
        totalProducts: pickedProductIds.length,
        loadMoreProducts,
        pickedProductId,
        setPickedProductId,
        product,
        setProduct,
        variant,
        setVariant,
        options,
        setOptions,
        sellingOption,
        setSellingOption,
        sellingPlanGroup,
        sellingPlan,
        setSellingPlan,
        isLoadingMoreProducts,
        imageGridFormat,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
