interface ParseableNumberFormat extends Intl.NumberFormat {
  parse?: (value: string) => number;
  shortFormat?: (value: number) => string;
  fullFormat?: (value: number) => string;
  percentageFormat?: (value: number) => string;
}

const formatter: ParseableNumberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const parse = (value: string | number | undefined | null): number =>
  parseFloat((value ? `${value}` : "0").replace("$", ""));

export const fullFormat = (value: number | string | undefined | null): string =>
  formatter.format(parse(value));

export const shortFormat = (
  value: number | string | undefined | null,
): string => fullFormat(value).replace(/\.\d+/, "");

export const percentageFormat = (value: number): string =>
  (value * 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) + "%";

export const roundFormat = (value: number): string =>
  "$" + Math.round(value).toLocaleString();

const currency = {
  parse,
  fullFormat,
  shortFormat,
  percentageFormat,
};

export default currency;
