import { compact, difference } from "./fn";
import { camelToSnakeCaseHash } from "./formatters/camelToSnakeCase";
import { reportError } from "./logging";

export class SafeString extends String {
  // https://stackoverflow.com/a/68075852/4949153
  // A random property just to make this type incompatible with the default `string` type.
  // Related to avoiding possible XSS using the MustacheContent helper.
  __HtmlMustacheAllowed = true;
}

export const mustacheReplacer = ({
  htmlTemplate: originalHtmlTemplate,
  fallback,
  fallbackText,
  ignoreMissingVariables = [],
  variables = {},
}: {
  htmlTemplate?: SafeString | null;
  fallback?: string | React.ReactNode;
  fallbackText?: string;
  ignoreMissingVariables?: string[];
  variables?: Record<string, unknown>;
}): string => {
  const allVariables = { ...compact(camelToSnakeCaseHash(variables)) };
  let tempHtmlTemplate = `${originalHtmlTemplate || ""}`;

  Object.entries(allVariables).forEach(([key, value]) => {
    const regex = new RegExp(`{{ *${key} *}}`, "g");

    // Eg. prevents XSS from a customer with `sf_first_name` as `<script>...</script>`
    const safeValue = `${value}`
      .replace(/&/g, "and")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");

    tempHtmlTemplate = tempHtmlTemplate.replace(regex, safeValue);
  });

  const missing = Array.from(tempHtmlTemplate.matchAll(/{{(.*)}}/g));
  if (missing.length) {
    if (fallbackText) return fallbackText;
    if (fallback) return "";

    const missingVariableNames = missing.map((regexResult) => regexResult[1]);
    if (difference(missingVariableNames, ignoreMissingVariables).length > 0) {
      reportError(
        `Mustache variables missing: '${tempHtmlTemplate}', ${JSON.stringify(
          variables,
        )}`,
      );
    }
  }

  tempHtmlTemplate = tempHtmlTemplate.replace(/{{.*}}/g, "");
  tempHtmlTemplate = tempHtmlTemplate.replace(/\\n/g, "<br/>");

  return tempHtmlTemplate;
};
