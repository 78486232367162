import priceCalculatorWithDiscount from "@microsite/features/product/priceCalculatorWithDiscount";
import {
  Adjustment,
  BaseValueUnit,
  ShopifyProductVariant,
  ShopifySellingPlan,
} from "@superfiliate/graphql-sdk/src/lib/__generated__";
import { OrderInterval } from "@utils/types";

interface UsePriceProps {
  variant: ShopifyProductVariant | undefined;
  sellingPlan: ShopifySellingPlan | undefined;
  discount?: BaseValueUnit | null;
}

/**
 * Formats a subscription price based on the selling plan properties
 *
 * @param variant - The variant to be parsed
 * @param sellingPlan - The selling plan to be applied
 */
const formatSubscriptionPrice = (
  price: number,
  variant: ShopifyProductVariant | undefined,
  sellingPlan: ShopifySellingPlan | undefined,
): number => {
  if (!sellingPlan) return price;

  const { adjustmentType, adjustmentValue } = sellingPlan;

  switch (adjustmentType) {
    case Adjustment.Percentage:
      return price * ((100 - Number(adjustmentValue)) / 100);
    case Adjustment.FixedAmount:
      return price - Number(adjustmentValue);
    case Adjustment.Price:
      return Number(adjustmentValue);
    default:
      return price;
  }
};

/**
 * This function centralizes all the pricing calculations for variants, with
 * or without subscription components
 *
 * It also applies the given lead discount defined for a microsite
 */
const priceCalculator = (params: UsePriceProps) => {
  const { variant, sellingPlan, discount = null } = params;

  const finalPrice = variant?.customPrice || variant?.price || 0;

  const compareAtPrice =
    variant?.customCompareAtPrice || variant?.compareAtPrice || finalPrice;

  return {
    [OrderInterval.ONE_TIME]: {
      originalPrice: compareAtPrice,
      discountedPrice: priceCalculatorWithDiscount(finalPrice, discount),
    },
    [OrderInterval.SUBSCRIPTION]: {
      originalPrice: formatSubscriptionPrice(
        compareAtPrice,
        variant,
        sellingPlan,
      ),
      discountedPrice: priceCalculatorWithDiscount(
        formatSubscriptionPrice(finalPrice, variant, sellingPlan),
        discount,
      ),
    },

    /* Legacy Fields - For compatibility */

    /* One-time*/
    oneTimePrice: undefined,
    oneTimeComparePrice: undefined,
    oneTimeDiscountedPrice: undefined,
    /* Subscription */
    subscriptionPrice: undefined,
    subscriptionComparePrice: undefined,
    subscriptionDiscountedPrice: undefined,
  };
};

export default priceCalculator;
