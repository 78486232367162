// check if an array of arrays contains a given array
// will return true even if the arrays are not in the same order
export function arrayIncludes<T>(
  arrayOfArrays: T[][],
  targetArray: T[],
): boolean {
  const sortedTargetArray = [...targetArray].sort();
  return arrayOfArrays.some((arr) => {
    const sortedArr = [...arr].sort();
    return (
      sortedArr.length === sortedTargetArray.length &&
      sortedArr.every((value, index) => value === sortedTargetArray[index])
    );
  });
}

// Iterates through all combinations of elements from a 2-dimensional array.
// e.g.: Suppose we have a 2-dimensional array [[1, 2, 3], [4, 5], [6, 7]]
// The iteration order will be [1,4,6] -> [1,4,7] -> [1,5,6] -> ... -> [3,5,7]
export function iterate2DimensionalArrayCombinations<T>(
  arrays: T[][],
  currentIndex = 0,
  currentIteration: T[] = [],
  iterationCallback?: (iteration: T[]) => void,
) {
  if (currentIndex === arrays.length) {
    iterationCallback?.(currentIteration);
    return;
  }

  for (const value of arrays[currentIndex]) {
    iterate2DimensionalArrayCombinations(
      arrays,
      currentIndex + 1,
      [...currentIteration, value],
      iterationCallback,
    );
  }
}
