import React from "react";

import cn from "@utils/cn";

export interface TypographyProps extends React.PropsWithChildren {
  className?: string;
  dynamicSize?: boolean;
}

/* Display Spec
 *
 * Reference - https://www.figma.com/file/4076VzvcBIFLxdvuPY1mEE/Storefront-Experiences---Style-Guide?type=design&node-id=910%3A6278&t=iD68V0PYYm4BRzfe-1
 */
const Display1: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h1
    className={cn(
      "font-display font-semibold",
      "text-4xl",
      {
        "lg:text-7xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h1>
);

const Display2: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h2
    className={cn(
      "font-display font-semibold",
      "text-3xl",
      {
        "lg:text-6xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h2>
);

const Display3: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h3
    className={cn(
      "font-display font-semibold",
      "text-2xl",
      {
        "lg:text-5xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h3>
);

/* Headings Spec
 *
 * Reference - https://www.figma.com/file/4076VzvcBIFLxdvuPY1mEE/Storefront-Experiences---Style-Guide?type=design&node-id=910-6293&t=iD68V0PYYm4BRzfe-4
 */
const Header1: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h1
    className={cn(
      "font-display font-semibold",
      "text-3xl",
      {
        "lg:text-4xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h1>
);

const Header2: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h2
    className={cn(
      "font-display font-semibold",
      "text-2xl",
      {
        "lg:text-3xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h2>
);

const Header3: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h3
    className={cn(
      "font-display font-semibold",
      "text-xl",
      {
        "lg:text-2xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h3>
);

const Header4: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h4
    className={cn(
      "font-display font-semibold",
      "text-lg",
      {
        "lg:text-xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h4>
);

const Header5: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h5
    className={cn(
      "font-header font-medium",
      "text-md",
      {
        "lg:text-lg": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h5>
);

const Header6: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h6
    className={cn(
      "font-header font-medium",
      "text-sm",
      {
        "lg:text-md": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h6>
);

const Header7: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <h6
    className={cn(
      "font-header font-light",
      "text-sm",
      {
        "lg:text-md": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </h6>
);

/* Form Spec
 *
 * Reference - https://www.figma.com/file/giTYeoQDOHvvyPiCRJqbhk/Style-Guide---App-%26-Michelangelo?node-id=511%3A343&t=j2qQCdIJCMXUTyOH-1
 */
const FormLabel: React.FC<TypographyProps & { htmlFor?: string }> = ({
  className,
  children,
  htmlFor,
}) => (
  <label
    htmlFor={htmlFor}
    className={cn(
      "block font-body text-sm font-normal tracking-wide text-gray-700",
      className,
    )}
  >
    {children}
  </label>
);

/* Body Spec
 *
 * Reference - https://www.figma.com/file/4076VzvcBIFLxdvuPY1mEE/Storefront-Experiences---Style-Guide?type=design&node-id=910-6320&t=iD68V0PYYm4BRzfe-4
 */
const Body1: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <div
    className={cn(
      "font-body font-light",
      "text-lg",
      {
        "lg:text-xl": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </div>
);

const Body2: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <div
    className={cn(
      "font-body font-light",
      "text-md",
      {
        "lg:text-lg": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </div>
);

const Body3: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <div
    className={cn(
      "font-body font-light",
      "text-sm",
      {
        "lg:text-md": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </div>
);

const Body4: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <div
    className={cn(
      "font-body font-light",
      "text-xs",
      {
        "lg:text-sm": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </div>
);

const Body5: React.FC<TypographyProps> = ({
  className,
  children,
  dynamicSize = true,
}) => (
  <div
    className={cn(
      "font-body font-light",
      "text-2xs",
      {
        "lg:text-xs": dynamicSize,
      },
      className,
    )}
  >
    {children}
  </div>
);

const Typography = {
  Display1,
  Display2,
  Display3,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Header7,
  FormLabel,
  Body1,
  Body2,
  Body3,
  Body4,
  Body5,
};

export default Typography;
